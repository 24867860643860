<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      <div>{{ header }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-6 py-5">
      <slot name="top" />
      <v-row>
        <v-col
          v-for="type in exportTypes"
          :key="type.header"
          class="pa-0 mb-4"
          cols="12"
          sm="6"
        >
          <BoxTypeSelector
            class="mx-0"
            :header="type.header"
            :subheader="type.subheader"
            :value="typeOfExport === type.key"
            :item-key="type.key"
            @input="handleTypeChange(type.key)"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="fieldsToExport.length && typeOfExport === 'specific'"
      >
        <v-col cols="12">
          <v-checkbox
            v-model="checkAllFields"
            :label="checkAllFields ? 'Uncheck All' : 'Check All'"
            hide-details
            class="mt-2"
            @change="handleCheckAll"
          />
        </v-col>
        <v-col
          v-for="(column, i) in fieldColumns"
          :key="i"
          cols="12"
          sm="6"
        >
          <v-checkbox
            v-for="field in column"
            :key="field"
            v-model="selectedFields"
            :label="field"
            :value="field"
            hide-details
            class="mt-2"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="divider-with-shadow d-flex justify-end align-center px-6 py-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        :disabled="typeOfExport === 'specific' && selectedFields.length === 0"
        @click="handleExport"
      >
        {{ actionButtonName }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BoxTypeSelector from "@/sharedComponents/BoxTypeSelectorSmall";

export default {
  name: "ExportDialog",
  components: {
    BoxTypeSelector,
  },
  props: {
    header: {
      default: () => "Export",
      type: String,
    },
    actionButtonName: {
      default: () => "Export",
      type: String,
    },
    fieldsToExport: {
      default: () => [],
      type: Array,
    }
  },
  data() {
    return {
      checkAllFields: false,
      typeOfExport: 'standard',
      selectedFields: [],
      exportTypes: [
        {
          header: 'Standard',
          subheader: 'Export all the fields',
          key: 'standard',
        },
        {
          header: 'Specific',
          subheader: 'Only export specific fields',
          key: 'specific',
        }
      ]
    };
  },
  computed: {
    fieldColumns() {
      const columns = [];
      const chunkSize = Math.round(this.fieldsToExport.length / 2);
      for (let i = 0; i < this.fieldsToExport.length; i += chunkSize) {
        columns.push(this.fieldsToExport.slice(i, i + chunkSize));
      }

      return columns;
    },
  },
  methods: {
    handleCheckAll(val) {
      this.checkAllFields = val;

      if (this.checkAllFields) {
        this.selectedFields = this.fieldsToExport;
      } else {
        this.selectedFields = [];
      }
    },
    handleExport() {
      this.$emit('export', {
        type: this.typeOfExport,
        fieldsToExport: this.selectedFields
      });
    },
    handleTypeChange(type) {
      this.typeOfExport = type;

      if (this.typeOfExport === 'standard') {
        this.selectedFields = [];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.popup-card {
  .v-btn__content {
    text-transform: none;
  }
  .v-card__title {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  fieldset {
    border-radius: 4px;
    border: solid 1px #dee7ee !important;
    background-color: #ffffff;
  }
}

::v-deep .v-label {
  font-size: 13px !important;
  color: #000;
}
</style>
