<template>
  <div class="search-table">
    <v-simple-table>
      <thead>
        <tr class="search-table__header">
          <th>Contact</th>
          <th>Action Code Type</th>
          <th>Action Source</th>
          <th>Date Time</th>
        </tr>
      </thead>
      <tbody class="search-table__content">
        <tr v-for="(item, index) in records" :key="index">
          <td class="search-table__id" style="text-align: left;display: flex;">
            <div>
              <img
                :src="profileImage(item.contact)"
                alt=""
                class="item-details-card__avatar mt-3 mr-4"
                style="max-width: 20px"
              >
            </div>

            <div>
              <a class="fake-link" @click="goToContactPage(item)">
                <span v-if="item.contact.firstName || item.contact.lastName">
                  {{ item.contact.firstName }} {{ item.contact.lastName }}
                </span>
                <span v-else>
                  N/A
                </span>
                <br>
                <small class="text--disabled" style="font-size: 11px;">ID: {{ item.contact.id }}</small>
              </a>
            </div>
          </td>
          <td>
            {{ item.actionCodeName }}
          </td>
          <td>
            {{ item.source.name || '-' }}
          </td>
          <td>
            {{ getDateWithTime(item.actionDate) }}
          </td>
        </tr>
        <tr v-if="!records.length">
          <td colspan="4">
            No record found.
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "ActionPhoneTable",
  components: {},
  mixins: [datesMixin],
  props: {
    records: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToContactPage(record) {
      this.$router.push({
        name: "ContactDetails",
        params: {
          id: record.contact.id,
        },
      });
    },
    profileImage(contact) {
      if (contact.pictureUrl) {
        return contact.pictureUrl;
      }
      if (contact?.displayContactEmail) {
        return contact.displayContactEmail.gravatarUrl + "?d=mp";
      }

      return 'https://www.gravatar.com/avatar/f10f02b98efb411a86ecf24ed26a918e?d=mp';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/contact-info-table.scss";
.item-details-card__avatar {
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}
</style>

